import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { debounce } from "lodash";
import { useFormik } from "formik";
import axios from "axios";
import { parse, stringify } from "querystring";
import Form from "./components/Form/Form";
import PropertyList from "./components/PropertyList/PropertyList";
import { bedroomOptions } from "./constants/bedroomsBreakpoints";
import { priceOptions } from "./constants/priceBreakpoints";
import { propertyTypeOptions } from "./constants/propertyTypes";
import AdditionalFilters from "./components/AdditionalFilters/AdditionalFilters";
import { sortingOptions } from "./constants/sortingOptions";

const AppContainer = styled.div`
  width: 100vw;
`;

const pageQuery = parse(window.location.search.replace("?", ""));
const pageQueryOptions = {};

Object.keys(pageQuery).forEach((key) => {
  if (["search", "status"].includes(key)) {
    pageQueryOptions[key] = pageQuery[key];
    return;
  }

  if (["bathrooms", "bedrooms", "parkings"].includes(key)) {
    pageQueryOptions[key] = bedroomOptions.find(
      ({ value }) => value === +pageQuery[key]
    );
    return;
  }

  if (key === "openHomes") {
    pageQueryOptions[key] = pageQuery[key] === "true";
    return;
  }

  if (["priceMax", "priceMin"].includes(key)) {
    pageQueryOptions[key] = priceOptions.find(
      ({ value }) => value === +pageQuery[key]
    );
    return;
  }

  if (key === "propertyType") {
    const propertyTypes = pageQuery[key].split(",");
    pageQueryOptions[key] = propertyTypes.map((t) =>
      propertyTypeOptions.find(({ value }) => t === value)
    );
    return;
  }

  if (key === "sort") {
    pageQueryOptions[key] = sortingOptions.find(
      ({ value }) => pageQuery[key] === value
    );
    return;
  }
  if (key === "suburb") {
    const suburbs = pageQuery[key].split(",");
    pageQueryOptions[key] = suburbs.map((s) => ({
      label: s,
      value: s,
    }));
    return;
  }

  if (
    ["landAreaMin", "landAreaMax", "floorAreaMin", "floorAreaMax"].includes(key)
  ) {
    pageQueryOptions[key] = {
      label: pageQuery[key] + "m²",
      value: pageQuery[key],
    };
    return;
  }

  pageQueryOptions[key] = {
    label: pageQuery[key],
    value: pageQuery[key],
  };
});

const getFiltersQueryString = (filters) => {
  const usedFilters = Object.keys(filters)
    .filter((k) =>
      Array.isArray(filters[k]) ? filters[k].length > 0 : !!filters[k]
    )
    .reduce((newFilters, filterKey) => {
      newFilters[filterKey] = Array.isArray(filters[filterKey])
        ? filters[filterKey]
            .filter((v) => v)
            .filter(({ value }) => value !== "select-all")
            .map(({ value }) => value)
            .join(",")
        : filters[filterKey].value ?? filters[filterKey];
      return newFilters;
    }, {});
  return stringify(usedFilters);
};

function App({ settings }) {
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getProperties = useCallback(
    async (filters) => {
      if (settings.filter_type === "commercial") {
        filters = { ...filters, filterType: "commercial" };
      }

      const query = getFiltersQueryString(filters);
      const propertiesData = await axios.get(
        `${process.env.REACT_APP_API_URL}api/properties?${query}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      setProperties(propertiesData.data);
      setIsLoading(false);
    },
    [settings]
  );

  const { handleChange, setFieldValue, handleSubmit, values } = useFormik({
    initialValues: {
      search: pageQueryOptions.search || "",
      city: pageQueryOptions.city || null,
      suburb: pageQueryOptions.suburb || [],
      propertyType: pageQueryOptions.propertyType || [],
      priceMin: pageQueryOptions.priceMin || null,
      priceMax: pageQueryOptions.priceMax || null,
      bathrooms: pageQueryOptions.bathrooms || null,
      bedrooms: pageQueryOptions.bedrooms || null,
      parkings: pageQueryOptions.parkings || null,
      landAreaMin: pageQueryOptions.landAreaMin || null,
      landAreaMax: pageQueryOptions.landAreaMax || null,
      floorAreaMin: pageQueryOptions.floorAreaMin || null,
      floorAreaMax: pageQueryOptions.floorAreaMax || null,
      openHomes: pageQueryOptions.openHomes || false,
      status: pageQueryOptions.status || "current",
      sort: pageQueryOptions.sort || null,
    },
    onSubmit: async (values) => {
      if (settings?.show_properties) {
        return await getProperties(values);
      }
      const query = getFiltersQueryString(values);
      window.location.href = `/${settings.redirect_url}${
        query ? `?${query}` : ""
      }`;
    },
  });

  useEffect(() => {
    if (!settings.show_properties) return;

    const query = getFiltersQueryString(values);
    let attempts = 0;
    while (true) {
      try {
        if (attempts === 3) break;

        const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${query}`;
        window.history.replaceState({ path: newUrl }, "", newUrl);
        break;
      } catch (e) {
        attempts++;
      }
    }
  }, [values, settings]);

  const updateProperties = useCallback(
    async (values) => {
      if (!settings?.show_properties) return;
      await getProperties(values);
    },
    [settings, getProperties]
  );

  const debouncedUpdateProperties = useMemo(
    () => debounce(updateProperties, 500),
    [updateProperties]
  );

  useEffect(() => {
    debouncedUpdateProperties(values);
  }, [values, debouncedUpdateProperties]);

  return (
    <AppContainer className="tommys-filter">
      <Form
        {...{ handleChange, setFieldValue, values, handleSubmit, settings }}
      />
      {settings?.show_properties && (
        <>
          <AdditionalFilters
            {...{ values, setFieldValue, type: settings.filter_type }}
          />
          <PropertyList
            {...{ properties, isLoading, type: settings.filter_type }}
          />
        </>
      )}
    </AppContainer>
  );
}

export default App;
