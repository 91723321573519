import React from "react";
import styled from "styled-components";
import Property from "./Property/Property";

const PropertyListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  row-gap: 30px;
  column-gap: 15px;
  max-width: 1080px;

  padding: 0px 20px;
  margin: 10px auto;
`;

const NoListings = styled.div`
  grid-column: span 3;
  font-size: 26px;
  text-align: center;
`;

const PropertyList = ({ properties, isLoading, type }) => {
  return (
    <PropertyListContainer id="properties">
      {properties.map((property) => {
        return <Property key={property.id} {...{ property, type }} />;
      })}
      {properties.length === 0 && !isLoading && (
        <NoListings>No Listings Found</NoListings>
      )}
    </PropertyListContainer>
  );
};

export default PropertyList;
