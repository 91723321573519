import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

if (process.env.NODE_ENV !== "production") {
  document.documentElement.style.setProperty("--color-blue-primary", "#1C4A9F");
  document.documentElement.style.setProperty("--primary-color", "#42b749");

  const settings = {
    show_properties: true,
    filter_type: "commercial",
    redirect_url: "buy",
    background:
      "https://tommys-commercial.disrupted.co.nz/wp-content/uploads/2021/09/mt-victoria-wellington-new-zealand-J22RENT.jpg",
  };

  ReactDOM.render(
    <React.StrictMode>
      <App {...{ settings }} />
    </React.StrictMode>,
    document.getElementById("root")
  );
} else {
  const targets = document.querySelectorAll(".tommys-filter-root");
  Array.prototype.forEach.call(targets, (target) => {
    const id = target.dataset.id;
    let settings = { ...window.tommysFilter[id], id };

    ReactDOM.render(<App settings={settings} />, target);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
