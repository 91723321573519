const areaBreakpoints = [50, 100, 150, 300, 500, 800, 1000, 1500];

export const getAreaBreakpointOptions = ({ min, max }) => {
  let breakpoints = areaBreakpoints;
  min && (breakpoints = breakpoints.filter((area) => area > min.value));
  max && (breakpoints = breakpoints.filter((area) => area < max.value));
  return breakpoints.map((area) => ({
    label: area + "m²",
    value: area,
  }));
};
