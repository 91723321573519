import React, { useEffect, useMemo, useRef, useState } from "react";
import Select, { components } from "react-select";
import styled from "styled-components";
import { bedroomOptions } from "../../constants/bedroomsBreakpoints";
import { priceOptions } from "../../constants/priceBreakpoints";
import { getPropertyTypeOptions } from "../../constants/propertyTypes";
import { suburbMap } from "../../constants/mappings";
import { arrayToOptions } from "../../utils/arrayToOptions";
import { isMobile } from "react-device-detect";
import { getAreaBreakpointOptions } from "../../constants/areaBreakpoints";
import {
  commercialStatusOptions,
  PropertyStatusButton,
} from "../AdditionalFilters/AdditionalFilters";

const cityOptions = arrayToOptions(Object.keys(suburbMap));

const allSuburbs = Object.keys(suburbMap)
  .reduce((suburbs, city) => [...suburbs, ...suburbMap[city]], [])
  .sort((a, b) => (a > b ? 1 : -1));

const Container = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 65vh;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--primary-color);
  background-image: url(${({ image }) =>
    image ?? "https://www.tommys.co.nz/wp-content/uploads/2020/09/MainBG.jpg"});
  background-size: cover;
  background-position: center center;
  user-select: none;
  z-index: ${({ isOpen }) => (isOpen ? 20 : 0)};
`;

const FormContainer = styled.div`
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 1;
`;

const Overlay = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const MoreFilters = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 15px;
  align-self: flex-start;
`;

const MoreButton = styled.div`
  font-size: 28px;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchButton = styled.button`
  background-color: var(--primary-color);
  border: none;
  height: 48px;
  width: 150px;
  margin: 0 10px;
  color: white;
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 576px) {
    width: auto;
    margin: 0px;
  }
`;

const Filtres = styled.div`
  width: 100%;
  max-height: ${({ isOpen }) => (isOpen ? "400px" : "0px")};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  transition: all 1s;
`;

const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(1000px / 4), 1fr));
  grid-gap: 10px;
  margin: 10px 0px;
`;

const SearchContainer = styled.div`
  height: 60px;
  background-color: white;
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 576px) {
    flex-direction: column;
    height: auto;
    align-items: stretch;
    background-color: transparent;
  }
`;
const SearchInput = styled.input`
  height: 60px;
  border: none;
  padding: 0;
  flex: 1;
  margin: 10px;
  outline: none;
  font-size: 15px;
  width: 100%;

  @media (max-width: 576px) {
    margin: 10px 0;
    flex: 0 0 60px;
    padding: 0 10px;
    width: auto;
  }
`;

const CheckboxLabel = styled.label`
  user-select: none;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0px;
  color: white;

  & > input {
    margin-left: 5px !important;
    margin-bottom: 0px;
  }
`;

const PropertyStatusContainer = styled.div`
  display: flex;

  @media (max-width: 576px) {
    justify-content: center;
  }
`;

const selectStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "100%",
    cursor: "pointer",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#000",
    backgroundColor: state.isHovered
      ? "#F4F4F4"
      : state.isSelected
      ? "#E4E4E4"
      : "white",
    display: "flex",
    alignItems: "center",
    lineHeight: 1,
    justifyContent: "space-between",
  }),
  control: (provided, state) => ({
    ...provided,
    height: "38px",
    cursor: "pointer",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "38px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: "rgb(51, 51, 51)",
    maxWidth: "90%",
    flexWrap: "nowrap",
  }),
  input: (provided, state) => ({
    ...provided,
    height: "auto",
  }),
};

const MultiValue = (props) => {
  return (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
};

const Option = (props) => (
  <div>
    <components.Option {...props}>
      <label>{props.label} </label>{" "}
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={(e) => null}
      />
    </components.Option>
  </div>
);

const MultiValueContainer = ({ selectProps, data }) => {
  if (selectProps.value.length >= selectProps.options.length) {
    if (data.value !== "select-all") return "";

    return "All Selected";
  } else {
    if (data.value === "select-all") return "";
  }

  const label = data.label;
  const allSelected = selectProps.value;
  const index = allSelected.findIndex((selected) => selected.label === label);
  const isLastSelected = index === allSelected.length - 1;
  const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ", ";
  const val = `${label}${labelSuffix}`;
  return val;
};

const addAnyOption = (options) => {
  const optionsWithAny = [...options];
  optionsWithAny.unshift({ label: "Any", value: "" });

  return optionsWithAny;
};

const selectAllOption = {
  label: "Select All",
  value: "select-all",
};
const deselectAllOption = {
  label: "Deselect All",
  value: "select-all",
};

const addSelectAllOption = (options, values) => {
  const optionsWithSelectAll = [...options];

  const isSelectAllSelected = values.some(
    ({ value }) => value === "select-all"
  );

  optionsWithSelectAll.unshift(
    options?.length + 1 <= values?.length || isSelectAllSelected
      ? deselectAllOption
      : selectAllOption
  );

  return optionsWithSelectAll;
};

const addSuffixToOptionLabel = (value, suffix) => {
  if (!value) return value;

  return {
    ...value,
    label: `${value.label}${suffix}`,
  };
};

const addPrefixToOptionLabel = (value, prefix) => {
  if (!value) return value;

  return {
    ...value,
    label: `${prefix}${value.label}`,
  };
};

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const Form = ({
  handleChange,
  values,
  setFieldValue,
  handleSubmit,
  settings,
}) => {
  const [isExtended, setIsExtended] = useState(false);
  const formRef = useRef();

  const handleSelect = (field, options) => (value, event) => {
    if (event.option?.value === "select-all") {
      if (event.action === "select-option") {
        return setFieldValue(field, [event.option, ...options]);
      } else {
        return setFieldValue(field, []);
      }
    }

    if (value?.value === "") {
      setFieldValue(field, null);
      return;
    }

    setFieldValue(field, value);
  };

  const suburbOptions = useMemo(() => {
    if (!values.city) {
      return arrayToOptions(allSuburbs);
    }
    const city = values.city?.value;
    return suburbMap[city]
      ? arrayToOptions(suburbMap[city].sort((a, b) => (a > b ? 1 : -1)))
      : [];
  }, [values.city]);

  // when updating city, need to check for suburbs
  useEffect(() => {
    const needToUpdateSuburb = values.suburb.some(
      (s) =>
        !suburbOptions.find(
          (o) =>
            o.value === s.value ||
            (s.value === "select-all" &&
              values.suburb.length === suburbOptions.length + 1)
        )
    );
    if (needToUpdateSuburb) {
      setFieldValue(
        "suburb",
        values.suburb.filter((s) =>
          suburbOptions.find((o) => o.value === s.value)
        )
      );
    }
  }, [setFieldValue, suburbOptions, values.city, values.suburb]);

  useEffect(() => {
    const handleKeyPressDown = (e) => {
      if (e.key === "Enter") {
        handleSubmit();
      }
    };
    const currentFormRef = formRef.current;
    currentFormRef?.addEventListener("keypress", handleKeyPressDown);

    return () =>
      currentFormRef?.removeEventListener("keypress", handleKeyPressDown);
  }, [formRef, handleSubmit]);

  const priceMinOptions = useMemo(() => {
    if (!values.priceMax) {
      return priceOptions;
    }

    return priceOptions.filter(({ value }) => value <= values.priceMax?.value);
  }, [values.priceMax]);

  const priceMaxOptions = useMemo(() => {
    if (!values.priceMin) {
      return priceOptions;
    }
    return priceOptions.filter(({ value }) => value >= values.priceMin?.value);
  }, [values.priceMin]);

  const getMobileSelectOptions = (isMulti) => {
    let mobileSelectOptions = {};
    if (isMobile) {
      mobileSelectOptions = {
        inputProps: { readOnly: true },
        isSearchable: false,
      };
    }

    if (isMulti) {
      mobileSelectOptions.closeMenuOnSelect = false;
    }

    return mobileSelectOptions;
  };

  const propertyTypeOptions = getPropertyTypeOptions(settings.filter_type);
  return (
    <Container ref={formRef} isOpen={isExtended} image={settings?.background}>
      <Overlay />
      <FormContainer>
        {settings.filter_type === "commercial" ? (
          <>
            <div className="filter-subsubheading">Find your next opportunity</div>
            <div className=" text-center main-heading">
              <h1 className="coms-medium">
                Search for commercial real estate
              </h1>
            </div>
          </>
        ) : (
          <div className=" text-center dark">
            <h1 className="main-heading dark">
              Find Your Dream<span className="highlight"> Home</span>
            </h1>
          </div>
        )}
        <SearchContainer>
          <SearchInput
            autoComplete="property-address"
            onChange={handleChange}
            name="search"
            value={values.search}
            placeholder="Enter Property Address, Suburb, Listing ID"
          />
          <SearchButton type="submit" onClick={() => handleSubmit()}>
            SEARCH
          </SearchButton>
        </SearchContainer>
        <MoreFilters onClick={() => setIsExtended((v) => !v)}>
          <MoreButton>+</MoreButton>
          {isExtended ? "Less" : "More"} Filters
        </MoreFilters>
        <Filtres isOpen={isExtended}>
          <FiltersContainer>
            <Select
              onChange={handleSelect("city")}
              value={values.city}
              placeholder="City"
              styles={selectStyles}
              options={addAnyOption(cityOptions)}
              {...getMobileSelectOptions()}
            />
            <Select
              onChange={handleSelect("suburb", suburbOptions)}
              components={{ Option, MultiValue, MultiValueContainer }}
              value={values.suburb}
              formatGroupLabel={formatGroupLabel}
              placeholder="Suburb"
              styles={selectStyles}
              options={addSelectAllOption(suburbOptions, values.suburb)}
              blurInputOnSelect={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
              isClearable={false}
              isMulti
              {...getMobileSelectOptions(true)}
            />
            <Select
              onChange={handleSelect("propertyType", propertyTypeOptions)}
              value={values.propertyType}
              placeholder="Property Type"
              styles={selectStyles}
              options={addSelectAllOption(
                propertyTypeOptions,
                values.propertyType
              )}
              components={{ Option, MultiValue, MultiValueContainer }}
              formatGroupLabel={formatGroupLabel}
              blurInputOnSelect={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
              isClearable={false}
              isMulti
              {...getMobileSelectOptions(true)}
            />
            <Select
              onChange={handleSelect("parkings")}
              value={addSuffixToOptionLabel(values.parkings, " parkings")}
              placeholder="Parking"
              styles={selectStyles}
              options={addAnyOption(bedroomOptions)}
              {...getMobileSelectOptions()}
            />
            <Select
              onChange={handleSelect("priceMin")}
              value={addPrefixToOptionLabel(values.priceMin, "> $")}
              placeholder="Price (Min)"
              styles={selectStyles}
              options={addAnyOption(priceMinOptions)}
              {...getMobileSelectOptions()}
            />
            <Select
              onChange={handleSelect("priceMax")}
              value={addPrefixToOptionLabel(values.priceMax, "< $")}
              placeholder="Price (Max)"
              styles={selectStyles}
              options={addAnyOption(priceMaxOptions)}
              {...getMobileSelectOptions()}
            />
            {settings.filter_type === "commercial" ? (
              <>
                <Select
                  onChange={handleSelect("landAreaMin")}
                  value={values.landAreaMin}
                  placeholder="Land Area (Min)"
                  styles={selectStyles}
                  options={getAreaBreakpointOptions({
                    max: values.landAreaMax,
                  })}
                  {...getMobileSelectOptions()}
                />
                <Select
                  onChange={handleSelect("landAreaMax")}
                  value={values.landAreaMax}
                  placeholder="Land Area (Max)"
                  styles={selectStyles}
                  options={getAreaBreakpointOptions({
                    min: values.landAreaMin,
                  })}
                  {...getMobileSelectOptions()}
                />
                <Select
                  onChange={handleSelect("floorAreaMin")}
                  value={values.floorAreaMin}
                  placeholder="Floor Area (Min)"
                  styles={selectStyles}
                  options={getAreaBreakpointOptions({
                    max: values.floorAreaMax,
                  })}
                  {...getMobileSelectOptions()}
                />
                <Select
                  onChange={handleSelect("floorAreaMax")}
                  value={values.floorAreaMax}
                  placeholder="Floor Area (Max)"
                  styles={selectStyles}
                  options={getAreaBreakpointOptions({
                    min: values.floorAreaMin,
                  })}
                  {...getMobileSelectOptions()}
                />
              </>
            ) : (
              <>
                <Select
                  onChange={handleSelect("bathrooms")}
                  value={addSuffixToOptionLabel(values.bathrooms, " bathrooms")}
                  placeholder="Bathrooms"
                  styles={selectStyles}
                  options={addAnyOption(bedroomOptions)}
                  {...getMobileSelectOptions()}
                />
                <Select
                  onChange={handleSelect("bedrooms")}
                  value={addSuffixToOptionLabel(values.bedrooms, " bedrooms")}
                  placeholder="Bedrooms"
                  styles={selectStyles}
                  options={addAnyOption(bedroomOptions)}
                  {...getMobileSelectOptions()}
                />
              </>
            )}
          </FiltersContainer>
          {settings.filter_type !== "commercial" && (
            <CheckboxLabel>
              Open Homes
              <input
                name="openHomes"
                type="checkbox"
                checked={values.openHomes}
                onChange={() => setFieldValue("openHomes", !values.openHomes)}
              />
            </CheckboxLabel>
          )}
          {settings.filter_type === "commercial" &&
            settings.show_properties === false && (
              <PropertyStatusContainer>
                {commercialStatusOptions.slice(0, 2).map(({ label, value }) => (
                  <PropertyStatusButton
                    key={value}
                    active={values.status === value}
                    onClick={() => setFieldValue("status", value)}
                  >
                    {label}
                  </PropertyStatusButton>
                ))}
              </PropertyStatusContainer>
            )}
        </Filtres>
      </FormContainer>
    </Container>
  );
};

export default Form;
