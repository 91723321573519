export const propertyTypes = [
  "House",
  "Apartment",
  "Home & Income",
  "Commercial",
  "Commercial Lease",
  "Commercial Development",
  "Lifestyle Property",
  "Unit",
  "Townhouse",
  "Section Res",
  "Lifestyle Section",
  "Studio",
  "Carpark",
];

export const commercialPropertyTypes = [
  "Retail",
  "Office",
  "Hotel/Motel/Leisure",
  "Investment Flats",
  "Industrial",
  "Warehouse",
  "Showroom",
  "Carpark",
  "Commercial Land",
  "Industrial Land",
  "Hotels Motels Lodges B&B",
  "Contractor",
  "Distribution",
  "Franchise",
  "Hospitality",
  "Manufacturing",
  "Professional",
  "Restaurant & Cafe",
  "Age Care Facilities",
  "Retail Food & Beverages",
  "Retail - Non Food",
  "Services",
  "Sporting",
  "Tourism",
  "Transport",
  "Wholesale",
  "Beauty & Health",
  "Education & Training",
  "Leisure & Entertainment",
  "Dairy & Superette",
  "Tourism",
  "Other",
];

const customLabelsMap = {
  "Section Res": "Residential Section",
};

const convertTypesToOptions = (types) =>
  types.map((t) => {
    return { label: customLabelsMap[t] ?? t, value: t };
  });

export const propertyTypeOptions = [
  ...propertyTypes,
  ...commercialPropertyTypes,
].map((t) => {
  return { label: customLabelsMap[t] ?? t, value: t };
});

export const getPropertyTypeOptions = (type) => {
  let options = convertTypesToOptions(propertyTypes);

  if (type === "commercial") {
    options = convertTypesToOptions(commercialPropertyTypes);
  }

  return options;
};
