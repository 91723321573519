export const sortingOptions = [
    {
      label: 'Sort',
      value: null
    },
    {
      label: 'Price: High to Low',
      value: 'price-desc'
    },
    {
      label: 'Price: Low to High',
      value: 'price-asc'
    },
    {
      label: 'Date: Newest First',
      value: 'date-desc'
    },
    {
      label: 'Date: Oldest First',
      value: 'date-asc'
    },
  ]